import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"befolyasolas-es-profilozas-alapkurzus"} />
		<Helmet>
			<title>
				Sikeres Kommunikáció - Sziklay Bence
			</title>
			<meta name={"description"} content={"Ismerd fel a másokban mélyen rejlő érzelmeket, elrejtett félelmeket, bizonytalanságokat és igényeket, mindezt akár hat percen belül."} />
			<meta property={"og:title"} content={"Sikeres Kommunikáció - Sziklay Bence"} />
			<meta property={"og:description"} content={"Ismerd fel a másokban mélyen rejlő érzelmeket, elrejtett félelmeket, bizonytalanságokat és igényeket, mindezt akár hat percen belül."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/mesterkulcs.webp?v=2024-03-07T19:53:21.189Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
		</Helmet>
	</Theme>;
});